import React from "react";

const HomePage = () => {
  return (
    <div className="">
      <div className="w-full h-screen bg-center bg-cover bg-earthGif">
        <div className="flex items-center justify-center w-full h-full bg-gray-900 bg-opacity-50 py-12">
          <div className="text-center w-full">
            <div className="container min-w-[70%] px-4 mx-auto">
              <div className="max-w-4xl mx-auto text-center">
                <h2 className="mb-10 text-2xl sm:text-4xl lg:text-5xl font-bold text-gray-100">
                  Welcome to world the best vulnerability scanner{" "}
                </h2>
                <a
                  href={`${window.location.protocol}//app.${window.location.host}`}
                  className="inline-block w-full md:w-auto mb-4 md:mr-6 py-5 px-8 text-sm font-bold uppercase border-2 border-transparent bg-gray-200 rounded hover:bg-gray-100 text-gray-800 transition duration-200"
                >
                  GET STARTED
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomePage;
