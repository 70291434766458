import React from "react";
import ComingSoonIMG from "../assets/mainHome/ComingSoonImg.png";

const ComingSoonComponent = () => {
  return (
    <div className="min-h-screen flex flex-col justify-center items-center">
      <div>
        <div className="text-5xl text-center mb-5 text-white dark:text-textColor">
          Coming Soon!
        </div>
        <div>
          <img
            src={ComingSoonIMG}
            alt="Coming-Soon-Img"
            className="w-[83%] h-[50%] mx-auto"
          />
        </div>
      </div>
    </div>
  );
};

export default ComingSoonComponent;
