import React from "react";
import Home from "./pages/staticPages/Home";

function App() {
  return (
    <>
      <div className="bg-backgroundColor w-full h-full">
        <Home />
      </div>
    </>
  );
}

export default App;
