import React from "react";
import ComingSoonComponent from "../../components/ComingSoonComponent";

const Contact = () => {
  return (
    <div className="h-full min-h-[920px] flex justify-center">
      <div className="h-[80%] w-[80%]">
        <ComingSoonComponent />
      </div>
    </div>
  );
};

export default Contact;
