import React from "react";

const About = () => {
  return (
    <div className="flex items-center gap-10 h-full xl:h-screen px-16">
      <div className="flex flex-col gap-5 w-full">
        <div className="flex flex-col gap-2">
          <p className="text-xl text-white">Attack Surface Discovery</p>
          <p className="text-gray-300 text-sm">
            Understanding an organization's vulnerabilities is impossible
            without tactical insights into its network footprint. By integrating
            open-source intelligence with leading open-source security scanning
            tools, we enable comprehensive attack surface discovery.
          </p>

          <p className="text-gray-300 text-sm">
            With the rapid deployment of internet assets, the attack surface is
            constantly expanding and evolving, making it challenging to map an
            organization’s external network footprint. Our solutions address
            this complexity. Start by utilizing our tools for domain and IP
            address data, then delve deeper into exposure analysis with hosted
            open-source vulnerability scanners that's called DOSER's. The
            DOSER's offers new insights and enables comprehensive reporting,
            facilitating informed decision-making.
          </p>
        </div>

        <div className="flex flex-col gap-2">
          <p className="text-xl text-white">Vulnerability Identification</p>
          <p className="text-gray-300 text-sm">
            No single vulnerability scanner can uncover every vulnerability
            across all your network assets. A combination of different scanning
            tools is essential for a comprehensive view of your exposure.
          </p>
          <p className="text-gray-300 text-sm">
            Our hosted vulnerability scanners are trusted and widely used by
            thousands of security professionals globally, leveraging the best in
            open-source technology.
          </p>
        </div>

        <div className="flex flex-col gap-2">
          <p className="text-xl text-white">Experience and Trust</p>

          <p className="text-gray-300 text-sm">
            In security, there is no one-size-fits-all solution. Effective
            protection requires effort and a deep understanding of the tools and
            resources available. By applying tools and knowledge strategically,
            you can accurately assess your vulnerabilities.
          </p>

          <p className="text-gray-300 text-sm">
            With over 20 years of experience in information security, we’ve
            encountered numerous vendor claims promising effortless solutions
            with flashy dashboards. The reality is that meaningful security
            takes expertise, not gimmicks.
          </p>

          <p className="text-gray-300 text-sm">
            Our journey began with hands-on experience in Red Team (penetration
            testing) and Blue Team (security analysis) roles for both corporate
            and federal sectors. From these experiences, we developed security
            solutions based on open-source tools and launched some basic online
            tools to share their value. That marked the start of VulDoser.
          </p>

          <p className="text-gray-300 text-sm">
            Today, VulDoser operates with a remote team across three continents
            and servers in the EU. We provide tactical security reporting to
            organizations of all sizes, delivering actionable insights to
            identify and address security gaps.
          </p>
        </div>
      </div>

      <div className="w-full xl:block hidden">
        <img
          src="./assets/about.jpg"
          alt="About"
          className="h-[700px] rounded-2xl object-cover"
        />
      </div>
    </div>
  );
};

export default About;
